import React, { type FC } from 'react';

import { Html } from '@/components/content/html';

export type StatisticsAboutCompanyProps = GraphqlSelect.Partners<'statisticsTitle' | 'statistics'>;

export const StatisticsAboutCompany: FC<StatisticsAboutCompanyProps> = ({ statisticsTitle, statistics }) => (
    <section className="box bg-dark c-white mt-72 py-80">
        <div className="container">
            <h2 style={{ gap: '15px' }} className="fs-32 d-flex flex-wrap ai-center">
                <img loading="lazy" width="110" height="37" src="/assets/logo-light.svg" alt="LoanDO" />

                {statisticsTitle}
            </h2>

            <div className="d-flex flex-wrap mt-32 g-32">
                {statistics.map((stat, id) => (
                    <div key={id} style={{ flex: '1 1 260px' }}>
                        <strong className="fs-56">{stat.title}</strong>

                        <Html className="fs-24">{stat.description.html}</Html>
                    </div>
                ))}
            </div>
        </div>
    </section>
);
