import React, { type FC } from 'react';

import { Html } from '@/components/content/html';

export type WhyOurCompanyProps = GraphqlSelect.Partners<'whyLoandoTitle' | 'whyLoando'>;

const icon = ['world', 'puzzle', 'money', 'city'];

export const WhyOurCompany: FC<WhyOurCompanyProps> = ({ whyLoandoTitle, whyLoando }) => {
    return (
        <section className="container mt-64">
            <h2 className="fs-40">{whyLoandoTitle}</h2>

            <div className="d-flex flex-wrap mt-32 g-32">
                {whyLoando.map((why, id) => (
                    <div key={id} style={{ flex: '1 1 260px', gap: '10px' }} className="d-flex flex-column">
                        <img
                            loading="lazy"
                            width="60"
                            height="60"
                            src={`/assets/partners/${icon.at(id % icon.length)}.svg`}
                            alt=""
                        />

                        <strong style={{ marginTop: '6px' }} className="fs-24">
                            {why.title}
                        </strong>

                        <Html>{why.description.html}</Html>
                    </div>
                ))}
            </div>
        </section>
    );
};
