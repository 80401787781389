import { graphql, type HeadFC, type PageProps } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { type FC } from 'react';

import { ContentWithHeader } from '@/components/content/content-with-header';
import { Html } from '@/components/content/html';
import { AppHead } from '@/components/head';
import { CompanyPartner, type CompanyPartnerProps } from '@/components/sections/partners/company-partner';
import { OurPartners, type OurPartnersProps } from '@/components/sections/partners/our-partners';
import {
    StatisticsAboutCompany,
    type StatisticsAboutCompanyProps,
} from '@/components/sections/partners/statistics-about-company';
import { WhyOurCompany, type WhyOurCompanyProps } from '@/components/sections/partners/why-our-company';

interface PartnersPageProps {
    page: GraphqlSelect.Partners<
        'id' | 'name' | 'lead' | 'image' | 'howToBecome' | 'whatIs' | 'metaTitle' | 'metaDescription'
    > &
        StatisticsAboutCompanyProps &
        CompanyPartnerProps &
        OurPartnersProps &
        WhyOurCompanyProps;
}

const PartnersPage: FC<PageProps<PartnersPageProps>> = ({ data: { page } }) => {
    return (
        <>
            <ContentWithHeader
                breadcrumbs={[{ name: page.name }]}
                image={page.image?.remoteFile && <GatsbyImage image={getImage(page.image.remoteFile)!} alt="" />}
            >
                <h1 className="fs-56">{page.name}</h1>

                {page.lead?.raw && (
                    <p
                        style={{ marginTop: 'clamp(10px, 2.5vw, 32px)' }}
                        className="fs-24"
                        dangerouslySetInnerHTML={{ __html: page.lead.raw }}
                    />
                )}
            </ContentWithHeader>

            <div style={{ alignItems: 'flex-start' }} className="fs-18 container d-flex flex-wrap mt-64 g-64">
                {[page.howToBecome, page.whatIs].map((section, id) => (
                    <Html key={id} as="section" style={{ flex: '1 1 450px' }}>
                        {`<h2 class="fs-36">${section.title}</h2>` + section.description.html}
                    </Html>
                ))}
            </div>

            <WhyOurCompany whyLoandoTitle={page.whyLoandoTitle} whyLoando={page.whyLoando} />
            <StatisticsAboutCompany statisticsTitle={page.statisticsTitle} statistics={page.statistics} />
            <OurPartners partnersCopy={page.partnersCopy} partnersList={page.partnersList} />
            <CompanyPartner becomePartner={page.becomePartner} />
        </>
    );
};

export const Head: HeadFC<PartnersPageProps> = ({ data: { page }, location: { pathname } }) => {
    return (
        <AppHead
            index
            title={page.metaTitle}
            description={page.metaDescription}
            canonical={pathname}
            ogImage={{ id: page.id, type: 'pages' }}
        />
    );
};

export const query = graphql`
    query PartnersPage {
        page: partners {
            id
            metaTitle
            metaDescription
            name

            lead {
                raw
            }

            howToBecome {
                title

                description {
                    html
                }
            }

            whatIs {
                title

                description {
                    html
                }
            }

            whyLoandoTitle
            whyLoando {
                title

                description {
                    html
                }
            }

            statisticsTitle
            statistics {
                title

                description {
                    html
                }
            }

            partnersCopy {
                title

                description {
                    html
                }
            }

            partnersList {
                ...RelatedFirms
            }

            becomePartner {
                title
                email
                phone

                description {
                    html
                }
            }

            image {
                remoteFile {
                    childImageSharp {
                        gatsbyImageData(height: 600)
                    }
                }
            }
        }
    }
`;

export default PartnersPage;
